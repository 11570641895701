// 个人中心接口
import { get, post } from '@/utils/request'

// 获取用户个人信息
export function queryUserInfo(params) {
    return get('/ctrl/tenant/sys/account/queryUserInfo', params);
}

// 保存用户个人信息
export function updateUserInfo(params) {
    return post('/ctrl/tenant/sys/account/updateUserInfo', params);
}

// 校验密码接口
export function checkPassword(params) {
    return post('/ctrl/tenant/sys/account/checkPassword', params);
}

// 修改密码接口
export function updatePassword(params) {
    return post('/ctrl/tenant/sys/account/updatePassword', params);
}