import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import Layout from '../views/layout/Layout.vue'
const Layout = () => import(/* webpackChunkName: 'Layout' */ '../views/layout/Layout')  // 公共头部、左侧菜单、主体内容框架
const Index = () => import(/* webpackChunkName: 'Index' */ '@/views/dashboard/viewHome') // 首页视图
const Login = () => import(/* webpackChunkName: 'Login' */ '@/views/backstagePage/login') // 登录界面
const userInfor = () => import(/* webpackChunkName: 'userInfor' */ '@/views/backstagePage/userInformation') // 个人信息
const enterpriseCustomer = () => import(/* webpackChunkName: 'enterpriseCustomer' */ '@/views/resourceManagement/enterpriseCustomer') // 企业客户

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
//解决vue路由重复导航错误
//获取原型对象上的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export const constantRouterMap = [{
  path: '/',
  component: Layout,
  redirect: '/resource/enterpriseCustomer',
  name: '首页',
  hidden: true,
  children: [{ path: '/resource/enterpriseCustomer', component: enterpriseCustomer }]
},{
  path: '/login',
  name: 'login',
  meta: {
    title: '致程管理后台'
  },
  component: Login
},{
  path:'/general',
  component: Layout,
  name: '通用页面',
  children: [{ path: 'userInfor', component: userInfor ,name: '个人信息'}]
}]



// 资源管理
const projectManage = () => import(/* webpackChunkName: 'projectManage' */ '@/views/resourceManagement/projectManage') // 项目管理
const newProjectItem = () => import(/* webpackChunkName: 'newProjectItem' */ '@/views/resourceManagement/newProjectItem') // 新建项目
// const enterpriseCustomer = () => import(/* webpackChunkName: 'enterpriseCustomer' */ '@/views/resourceManagement/enterpriseCustomer') // 企业客户
const newCustomerItem = () => import(/* webpackChunkName: 'newCustomerItem' */ '@/views/resourceManagement/newCustomerItem') // 新建客户
const renewalRecord = () => import(/* webpackChunkName: 'renewalRecord' */ '@/views/resourceManagement/renewalRecord') // 续费记录
const RouterResource = {
  path: '/resource',
  component: Layout,
  name: '资源管理',
  icon: 'icon-ziyuanguanli',
  code: 'RES', // 权限编码，后台拿的
  children: [
    { path: 'projectManage', component: projectManage, name: '项目管理', code: 'RES_PJT', meta: { code: 'RES_PJT' } },
    { path: 'newProjectItem', component: newProjectItem, name: '新建项目', hidden: true },
    { path: 'enterpriseCustomer', component: enterpriseCustomer, name: '企业客户', code: 'RES_ENC', meta: { code: 'RES_ENC' } },
    { path: 'newCustomerItem', component: newCustomerItem, name: '新建客户', hidden: true },
    { path: 'renewalRecord', component: renewalRecord, name: '续费记录', code: 'RES_REN', meta: { code: 'RES_REN' } },
  ]
}

// 系统管理
const menuManage = () => import(/* webpackChunkName: 'menuManage' */ '@/views/systemManagement/menuManage') // 菜单管理
const employeeManage = () => import(/* webpackChunkName: 'employeeManage' */ '@/views/systemManagement/employeeManage') // 员工管理
const notification = () => import(/* webpackChunkName: 'notification' */ '@/views/systemManagement/notification') // 公告通知
const newAnnouncement = () => import(/* webpackChunkName: 'newAnnouncement' */ '@/views/systemManagement/newAnnouncement') // 新建公告
const protocolManage = () => import(/* webpackChunkName: 'protocolManage' */ '@/views/systemManagement/protocolManage') // 协议管理
const newProtocol = () => import(/* webpackChunkName: 'newProtocol' */ '@/views/systemManagement/newProtocol') // 新建协议
const systemTopic = () => import(/* webpackChunkName: 'systemTopic' */ '@/views/systemManagement/systemTopic') // 系统主题
const RouterSystem = {
  path: '/system',
  component: Layout,
  name: '系统管理',
  icon: 'icon-xitongguanli',
  code: 'SYS', // 权限编码，后台拿的
  children: [
    { path: 'menuManage', component: menuManage, name: '菜单管理'},
    { path: 'employeeManage', component: employeeManage, name: '员工管理' },
    { path: 'notification', component: notification, name: '公告通知' },
    { path: 'newAnnouncement', component: newAnnouncement, name: '新建公告', hidden: true },
    { path: 'protocolManage', component: protocolManage, name: '协议管理' },
    { path: 'newProtocol', component: newProtocol, name: '新建协议', hidden: true },
    { path: 'systemTopic', component: systemTopic, name: '系统主题' }
  ]
}

export const asyncRouterMap = [RouterResource, RouterSystem] // 用来存放菜单栏里面的路由
let router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ((document.querySelector('.main_wrapper') || document.querySelector('body')).scrollTop = 0), // 每次跳转路由都会滚动到最上面
  routes: constantRouterMap.concat(asyncRouterMap) // 将基础路由和菜单路由合并
})
// const routes = [
//   {
//     path: '/',
//     name: 'Layout',
//     component: Layout
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/backstagePage/login.vue')
//   }
// ]


// const router = new VueRouter({
//   mode: 'hash',
//   routes
// })
router.afterEach((to, from) => {
  console.log(to);
})
export default router
