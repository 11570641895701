const app = {
    state: {
        disMessageDrawer: false,
        // secondaryMenu: null
    },
    mutations: {
        SET_DRAWER: (state, view) => {
            state.disMessageDrawer = view
        },
        // SET_SECONDARYMENU: (state, view) => {
        //     state.secondaryMenu = view
        // }
    },
    actions: {
        setMessageDrawer({ commit }, view) {
            commit('SET_DRAWER', view)
        },
        // setSecondaryMenu({ commit }, view) {
        //     commit('SET_SECONDARYMENU', view)
        // }
    }

}

export default app