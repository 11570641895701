import Cookies from "js-cookie";

const TokenKey = 'admin-Token'

// 拿到token
export function getToken() {
    return sessionStorage.getItem(TokenKey)
}
// 设置token
export function setToken(token) {
    return sessionStorage.setItem(TokenKey, token)
}
// 删除token
export function removeToken() {
    return sessionStorage.removeItem(TokenKey)
}

// localStorage方法的二次封装
export function getItem(key) {
    if (window.localStorage) {
        const val = localStorage[key]
        if (val) {
            try {
                return JSON.parse(val)
            } catch (ex) { }
        }
    }
    return null
}
export function setItem(key, val) {
    if (window.localStorage) {
        localStorage[key] = JSON.stringify(val)
    }
}
export function delItem(key) {
    if (window.localStorage) {
        localStorage.removeItem(key)
    }
}
