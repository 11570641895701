import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import data from './modules/data'
import app from './modules/app'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => {
    return {
      data: state.data
    }
  }
})

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    data,
    app,
    user,
  },
  getters
})

export default store
