// 系统主题接口
import { get, post } from '@/utils/request';

// 获取系统主题数据接口
export function getTheme(params) {
    return get('/ctrl/tenant/sys/getTheme', params);
}

// 修改保存系统主题
export function getUpdateTheme(params) {
    return post('/ctrl/tenant/sys/updateTheme', params, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}