import axios from '@/utils/request'
import { asyncRouterMap } from '@/router'


export function pageUtilsInstall(Vue) {
    // 顶部消息提示
    Vue.prototype.$tip = function (type, msg) { // type:0 成功; 1 警告; 2 错误
        this.$message({
            showClose: false,
            message: msg,
            // type: type === 0 ? 'warning' : 'success'
            // type: type === 0 ? 'success' : (type === 1 ? 'warning' : 'error')
            type: type === 0 ? 'success' : (type === 1 ? 'warning' : (type === 2 ? 'error' : 'info'))
        })
    }
    Vue.prototype.$http = {
        get: function (url, params) {
            return axios({
                url: url,
                method: 'get',
                params
            })
        },
        post: function (url, params) {
            return axios({
                url,
                method: 'post',
                data: params
            })
        },
        payload: function (url, params) {
            return axios({
                url,
                method: 'post',
                data: params
            })
        }
    }
    // Vue.prototype.$powerM = function (_this, str, code = null) {
    //     if (str) {
    //         let arr = str.split('/')
    //         arr.splice(0, 1)
    //         // console.log(arr);
    //         let obj = asyncRouterMap.filter(v => {
    //             return v.path === '/' + arr[0]
    //         })
    //         obj = obj[0]
    //         console.log(obj);
    //         let item = obj.children.filter(v => {
    //             return v.path === arr[1]
    //         })
    //         item = item[0]
    //         console.log(item);
    //         code = item.code
    //         // console.log(code);
    //     }
    //     if (this.$store.state.app.secondaryMenu) {
    //         let power = this.$store.state.app.secondaryMenu[code]
    //         console.log(this.$store.state.app.secondaryMenu)
    //         // 看不懂神奇的代码
    //         if (power) {
    //             power.forEach(v => {
    //                 _this[v] = true
    //             })
    //         }
    //         this.timer = null
    //     } else {
    //         this.timer = setTimeout(() => {
    //             this.timer = null
    //             this.$powerM(_this, str, code)
    //         }, 50)
    //     }
    // }
    // 路由返回上级
    Vue.prototype.$closeCurrentPage = function (path) {
        this.$store.dispatch('delVisitedViews', this.$route).then((views) => {
            if (path) {
                // 验证是否原本就带了参数的
                path = /\?\b[A-Za-z0-9]+=/.test(path) ? path + '&from=tap' : path + '?&from=tap'
                this.$router.push(path)
            } else {
                this.$router.go(-1)
            }
            // const latestView = views.slice(-1)[0]
            // if (latestView) {
            //   latestView.path = /\?\b[A-Za-z0-9]+=/.test(latestView.path) ? latestView.path + '&from=tap' : latestView.path + '?&from=tap'
            //   this.$router.push(latestView.path)
            // } else {
            //   this.$router.push('/')
            // }
        })
    }
}