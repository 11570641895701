const data = {
    state: {
        loading: false, // 全局加载方法
        appMain: { // appmain这个外层元素的一些基本数据，用的比较多，所以也存起来
            width: '100%',
            height: '980px'
        },
        themeDataArr:{}, // 存储主题数据
    },
    getters: {
        // 获取加载状态
        getLoadStatus: state => {
            return state.loading
        },
        getAppMain: state => {
            return state.appMain
        },
        // 获取主题数据
        getThemeDataArr: state => {
            return state.themeDataArr
        }
    },
    mutations: {
        setLoading: (state, status) => { state.loading = status }, // 加载状态
        setAppMain: (state, node) => { state.appMain = node }, // 参考base代码过来的，类似于调整 appMain组件 高度
        setThemeDataArr: (state, themeDataArr) => { state.themeDataArr = themeDataArr },  // 主题数据
    },
    actions: {
        // 异步获取加载状态
        setLoadingAsync: async (context, loading) => {
            context.commit('setLoading', loading)
        },
        setAppMainAsync(context, node) {
            context.commit('setAppMain', node)
        },
        setThemeDataArrAsync(context, themeDataArr){
            context.commit('setThemeDataArr', themeDataArr)
        }
    }
}

export default data

// export default new Vuex.Store({
//     state: {
//     },
//     getters: {
//     },
//     mutations: {
//     },
//     actions: {
//     },
//     modules: {
//     }
// })
