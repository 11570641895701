import { get, post } from '@/utils/request';

// 验证码获取
export function captcha(params) {
    return get('/ctrl/tenant/sys/captcha', params);
}

// 登录接口
export function login(params) {
    return post('/ctrl/tenant/sys/login', params);
}

// 退出登录
export function logout(params) {
    return post('/ctrl/tenant/sys/logout', params);
}