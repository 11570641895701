import { getToken, setToken, removeToken, getItem, setItem, delItem } from '@/utils/auth'
import { login, logout } from '@/api/login'
import { queryUserInfo } from '@/api/user'

/*
  刷新当前页面头像不丢失
*/
function getRefreshUserInfo() {
    if (getItem('manage-userInfo')) {
        const { name, avatar, id, account } = getItem('manage-userInfo')
        if (getToken()) {
            return { name, avatar, id, account }
        }
    }
    return {}
}

const user = {
    state: {
        user: '',
        token: getToken(),
        name: getRefreshUserInfo().name,
        avatar: getRefreshUserInfo().avatar,
        id: getRefreshUserInfo().id,
        account: getRefreshUserInfo().account,
    },

    mutations: {
        // 登录token
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        // 用户头像
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        // 用户名字
        SET_NAME: (state, name) => {
            state.name = name
        },
        // 用户账号
        SET_ACCOUNT: (state, account) => {
            state.account = account
        },
        // 用户id
        SET_ID: (state, id) => {
            state.id = id
        }
    }, 

    actions: {
        // 用户登录
        LoginByUsername({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    const token = response.data.accessToken
                    setToken(token)
                    commit('SET_TOKEN', token)
                    resolve(response)
                }).catch(error =>{
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetUserInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                queryUserInfo().then(res => {
                    console.log(res.data);
                    commit('SET_AVATAR', res.data.avatar)
                    commit('SET_NAME', res.data.name)
                    commit('SET_ACCOUNT', res.data.account)
                    commit('SET_ID', res.data.id)
                    setItem('manage-userInfo', { id: res.data.id, avatar: res.data.avatar, name: res.data.name, account: res.data.account })
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        
        // 登出
        LogOut({ commit, state }){
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    removeToken()
                    sessionStorage.clear()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

    }
}

export default user