// import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/reset.css'
import '@/css/iconfont.css' // 字体图标
import axios from 'axios';
import { pageUtilsInstall } from '@/utils/vueCommon'
import qs from 'qs'

import VueQuillEditor from 'vue-quill-editor' // 富文本编辑器引入
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueWechatTitle from 'vue-wechat-title' // 设置标签标题


Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
pageUtilsInstall(Vue);
Vue.prototype.qs = qs;
Vue.use(VueQuillEditor)
Vue.use(VueWechatTitle)


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
