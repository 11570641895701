<template>
  <div id="app" style="height:100%" v-wechat-title="getThemeTag.title">
    <keep-alive v-if="$route.path !== '/resource/enterpriseCustomer'">
      <router-view v-if="isRouterAlive"></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { getTheme } from '@/api/systemTopic'
import VueEvent from '@/utils/VueEvent'
export default {
  name: 'App',

  provide () { // 在祖先组件中通过 provide 提供变量
    return {
      reload: this.reload, // 声明一个变量
    }
  },

  data () {
    return {
      isRouterAlive: true, // 控制 router-view 是否显示达到刷新效果
      getThemeTag: {
        title: '', // 浏览器标题
        icon: '', // 浏览器图标
      },
    }
  },

  created () {
    if (!getToken()) {
      // 用户并未登录 跳转到登录界面
      this.$router.push('/login')
    }
    this.getThemeData()
    
  },

  mounted () {
    window.addEventListener('onbeforeunload ', this.unload)
    
  },

  methods: {
    unload () {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },

    // provide中声明的变量
    reload () { // 通过 this.isRouterAlive 控制 router-view 达到刷新效果
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 获取系统主题内容
    getThemeData(){
      getTheme().then(response => {
        if(response.resultCode === 0){
            this.getThemeTag.title = response.data.backgroundName
            this.getThemeTag.icon = response.data.webPagePathName
            console.log(this.getThemeTag);
            VueEvent.$emit('to-theme', response.data)
            this.$store.dispatch('setThemeDataArrAsync', response.data)
            // 动态修改浏览器图标
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = response.data.webPagePathName
            document.getElementsByTagName('head')[0].appendChild(link)
        }
      }).catch(error => {
          console.log(error);
      })
    },
  }
}
</script>

<style lang="less">
@import './css/index.css';
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
html,body{
  width: 100%;
  height: 100%;
}
</style>
